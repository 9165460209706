import { Accordion } from '@reach/accordion'
import { Dispatch, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterAccordionItem } from '../FilterAccordionItem'
import { Checkbox } from './components/Checkbox'
import { Icon } from 'elements/Icon/Icon'
import { FilterType } from 'typings/api'
import { useRouter } from 'next/router'

type Props = {
  aggregations: FilterType
  setFilterClose?: Dispatch<boolean>
  uuid: string
}

export const Filter = ({ aggregations, setFilterClose, uuid }: Props) => {
  const { t } = useTranslation('pop')
  const router = useRouter()
  const [indices, setIndices] = useState([])

  useEffect(() => {
    const closeAccordion = (_url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setIndices([])
      }
    }
    router.events.on('routeChangeStart', closeAccordion)

    return () => {
      router.events.off('routeChangeStart', closeAccordion)
    }
  }, [router])

  const toggleItem = (toggledIndex: number) => {
    if (indices.includes(toggledIndex)) {
      setIndices(indices.filter((currentIndex) => currentIndex !== toggledIndex))
    } else {
      setIndices([...indices, toggledIndex].sort())
    }
  }

  const handleReset = async () => {
    // remove filter from query
    delete router.query.filter
    // set new url
    await router.push(router, null, { shallow: true })
  }

  if (!aggregations?.length) {
    return null
  }

  return (
    <>
      <div className="flex flex-row justify-between">
        <h2 className="mb-6 flex flex-row items-center justify-center text-xl font-bold text-black">
          {t('Filter')}{' '}
          {router.query.filter && (
            <button onClick={handleReset} className="text-red" title={t('Remove Filter')}>
              <Icon name="cross" className="m-auto h-5 w-5" />
            </button>
          )}
        </h2>
        <button
          className="flex h-10 w-10 rounded-full focus:outline-none focus:transition focus:duration-500 focus:ease-in-out md:hidden lgx:h-12 lgx:w-12"
          onClick={() => setFilterClose(false)}
        >
          <Icon name="cross" className="m-auto h-6 w-6 lgx:h-7 lgx:w-7" />
        </button>
      </div>
      <Accordion onChange={toggleItem} index={indices}>
        {aggregations?.map((aggregation, i) => (
          <FilterAccordionItem
            key={`${uuid}-${aggregation.key}`}
            itemHeading={aggregation.label}
            isOpen={indices.includes(i)}
          >
            <ul className="mt-2 pl-7">
              {aggregation.values.map((value) => (
                <li key={value.label}>
                  <Checkbox label={value.label} value={value.key} attributeCode={aggregation.key} />
                </li>
              ))}
            </ul>
          </FilterAccordionItem>
        ))}
      </Accordion>
    </>
  )
}
