import { ProductOverviewVariant } from '~/typings/api'
import { parseFilterString } from './parseFilterString'

export type FilterProductsProps = {
  filter: ReturnType<typeof parseFilterString>
  products: ProductOverviewVariant[]
}

export const filterProducts = ({
  filter,
  products,
}: FilterProductsProps): ProductOverviewVariant[] => {
  const attributeCodes = Object.keys(filter)
  if (!filter || !attributeCodes.length) {
    return products
  }
  const filteredProducts: { [key: string]: ProductOverviewVariant[] } = {}
  attributeCodes.forEach((attributeCode) => {
    // TODO: figure out how to not change the sorting by filtering
    ;(filter[attributeCode] as string[]).forEach((filter) => {
      const hasSameFilter = products.filter((product) =>
        product.filter.find((prodFilter) => prodFilter === filter),
      )
      Array.isArray(filteredProducts[attributeCode])
        ? filteredProducts[attributeCode].push(...hasSameFilter)
        : (filteredProducts[attributeCode] = hasSameFilter)
    })
  })
  /**
   * iterate over all attributeCodes in the filtered products
   * and check if every product is present in every value
   */
  const val = Object.keys(filteredProducts).map((key) => {
    const currentProducts = filteredProducts[key]
    return currentProducts.filter((product) =>
      Object.keys(filteredProducts).every((key) =>
        filteredProducts[key].find((filterProduct) => filterProduct === product),
      ),
    )
  })
  const flattenedUnique = [
    ...new Set(Object.values(val).reduce((prev, current) => [...prev, ...current], [])),
  ]
  return flattenedUnique
}
