import { FILTER_SEPARATOR, KEY_SEPARATOR, VALUE_SEPARATOR } from '~/lib/constants'
import { parseFilterString } from './parseFilterString'

export type GenerateFilterStringProps = {
  // key1:value1,value2|key2:value3,value4
  filterString: string
  // brand-da12805b-c608-48cd-a94e-b0e9231a1b4e
  value: string
  // brand
  attributeCode: string
}

export const generateFilterString = ({
  filterString,
  attributeCode,
  value,
}: GenerateFilterStringProps): string => {
  const filters = parseFilterString(filterString)
  //  add a new set with the selected value
  if (!Object.keys(filters).length) {
    return `${attributeCode}${KEY_SEPARATOR}${value}`
  }
  /**
   * if the attribute code is already there and it has the new value already,
   * delete the value from the string (deselect filter), otherwise
   * add it to the array
   */
  const attributeCodeAlreadyPresent: string[] = filters[attributeCode]
  if (attributeCodeAlreadyPresent) {
    // value is already there so we want to deselect it
    if (attributeCodeAlreadyPresent.find((existingValue) => existingValue === value)) {
      filters[attributeCode] = attributeCodeAlreadyPresent.filter(
        (existingValue) => existingValue !== value,
      )
      // value was not present so we add it
    } else {
      filters[attributeCode].push(value)
    }
    // attribute code is new so we just add it
  } else {
    filters[attributeCode] = [value]
  }

  /**
   * Turns filter object back into a string with the expected spearators,
   * but only if an attribute has any values left associated with it
   */
  return Object.keys(filters).reduce((stringSoFar, currentBrand) => {
    return `${
      stringSoFar.length && filters[currentBrand].length
        ? `${stringSoFar}${FILTER_SEPARATOR}`
        : stringSoFar
    }${
      filters[currentBrand].length
        ? `${currentBrand}${KEY_SEPARATOR}${filters[currentBrand].join(VALUE_SEPARATOR)}`
        : ''
    }`
  }, '')
}
