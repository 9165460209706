import { ProductCard } from 'components/ProductCard/ProductCard'
import { Spinner } from 'components/Spinner/Spinner'
import { NextSeo } from 'next-seo'
import { useTranslation } from 'react-i18next'
import Image from '@next/image'
import { Icon } from 'elements/Icon/Icon'
import { Breadcrumb } from 'elements/Breadcrumb/Breadcrumb'
import '@reach/accordion/styles.css'
import { Filter } from './components/Filter/Filter'
import { useState } from 'react'
import { selectContentModule } from 'lib/selectContentModule'
import { usePIMData } from 'hooks/usePIMData'
export type Props = { urlKey: string }
import invariant from 'tiny-invariant'
import { Button } from 'elements/Button/Button'
import { PageType } from 'typings/api'
import { useRouter } from 'next/router'
import { parseFilterString } from './components/Filter/lib/parseFilterString'
import { filterProducts } from './components/Filter/lib/filterProducts'
import clsx from 'clsx'
import _ from 'lodash'

export const PAGE_SIZE = 16

export const ProductOverviewPage = ({ urlKey }: Props) => {
  const router = useRouter()
  const { t } = useTranslation('pop')
  const [openFilter, setOpenFilter] = useState(false)
  const { data, isLoading, isError, error } = usePIMData<PageType>(urlKey)
  invariant(data.type === 'pop')

  if (isLoading) {
    return <Spinner fullsize />
  }

  if (isError && process.env.NODE_ENV === 'development') {
    return <div>{error.message}</div>
  } else if (isError) {
    return <div>Something went wrong</div>
  }

  const filteredProducts = filterProducts({
    filter: parseFilterString(router.query.filter as string),
    products: data.main.products,
  })

  return (
    <>
      <NextSeo
        title={data?.title}
        description={data?.meta_description}
        additionalMetaTags={[{ name: 'title', content: data?.meta_title }]}
      />

      <div>
        <div className="relative mb-4 min-h-[200px] md:mb-10">
          {data.main.titel_banner ? (
            <h1 className="relative z-10 mb-0 py-8 px-4 text-4xl leading-12 text-white md:p-20 md:text-6xl md:leading-18">
              {data.main.titel_banner}
            </h1>
          ) : null}
          <Image
            src={data?.main.bannerbild.url}
            alt={data?.main.bannerbild.alt}
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>

        <div className="mx-auto max-w-screen-xl">
          {data.breadcrumb && data.breadcrumb.length >= 1 && (
            <Breadcrumb breadcrumbData={data.breadcrumb} pageTitle={data?.title} />
          )}
          {data.main.content_modules &&
            data.main.content_modules.map((mod, i) => selectContentModule(mod, i))}

          <div className="relative z-10 mb-6 block md:hidden">
            <div className="flex flex-row bg-gray-light">
              <button
                onClick={() => setOpenFilter(true)}
                className="flex flex-1 items-center justify-center py-4 font-bold text-gray-dark"
              >
                {t('Filter')}
                <Icon name="filter" className="ml-2" />
              </button>
            </div>
            {openFilter && (
              <div className="absolute top-full w-full bg-gray-light px-10 pb-10 shadow-md">
                {data.main.filter && (
                  <Filter
                    aggregations={data.main.filter}
                    setFilterClose={setOpenFilter}
                    uuid={data.uuid}
                  />
                )}
                <Button className="w-full sm:w-full" onClick={() => setOpenFilter(false)}>
                  {t('FilterCTA')}
                </Button>
              </div>
            )}
          </div>

          {data.main.products && (
            <div className="mb-6 grid grid-cols-1 md:gap-x-2 gap-y-4 px-4 md:mb-8 md:grid-cols-4 md:grid-rows-[auto,1fr] md:gap-8 md:gap-y-8 md:border-b md:border-gray md:px-16 md:pb-8">
              {
                <div className="hidden md:row-span-full md:block">
                  <div>
                    {data.main.filter && (
                      <Filter aggregations={data.main.filter} uuid={data.uuid} />
                    )}
                  </div>
                </div>
              }
              <div
                className={clsx(
                  'mb-6 flex-row items-center md:mb-0 md:flex',
                  data.main.filter.length > 0 ? 'col-span-3' : 'col-span-4',
                )}
              >
                <h2 className="mb-0 text-xl font-bold text-black">
                  {data.main.titel_produktlistung}
                </h2>
              </div>
              <ul
                className={clsx(
                  '2 mb-auto grid grid-cols-1 gap-x-2 gap-y-4 md:grid-cols-3 md:gap-8',
                  data.main.filter.length > 0
                    ? 'md:col-span-3 md:col-start-2'
                    : 'md:col-span-4 md:col-start-1',
                )}
              >
                {filteredProducts?.map((item) => (
                  <li key={item.uuid} className="self-stretch">
                    <ProductCard
                      name={item.name}
                      short_description={item.beschreibung}
                      image={item.bild}
                      url_key={item.path}
                      hmv_label={item.label_nummer}
                      hmv_number={item.nummer}
                      filter={item.filter}
                      neuprodukt={item.neuprodukt}
                    />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* {data.main.products && (
            <>
              <p className="mb-4 text-center">
                <span className="font-bold">
                  {data.main.products.length * PAGE_SIZE < data.main.products.length
                    ? data.main.products.length * PAGE_SIZE
                    : data.main.products.length}
                </span>{' '}
                {t('x out of y products viewed', {
                  y: data.main.products.length,
                })}
              </p>
              <progress
                className="progress-bar mx-auto mb-6 block h-[3px] w-full max-w-sm"
                value={
                  data.main.products.length * PAGE_SIZE < data.main.products.length
                    ? data?.pages.length * PAGE_SIZE
                    : data.main.products.length
                }
                max={data.main.products.length}
              />
            </>
          )} */}
          {/*
          {hasNextPage && category?.display_mode === 'PRODUCTS' ? (
            isFetchingNextPage ? (
              <Spinner className="mx-auto" />
            ) : (
              <button
                onClick={() => {
                  fetchNextPage()
                }}
                className="mx-auto block w-full max-w-sm bg-red py-4 font-medium uppercase text-white transition-colors hover:bg-red-light"
              >
                {t('Load More')}
              </button>
            )
          ) : null}
          <div dangerouslySetInnerHTML={{ __html: category.description }}></div>*/}
        </div>
      </div>
    </>
  )
}
