import useElementSize from '@charlietango/use-element-size'
import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  useAccordionItemContext,
} from '@reach/accordion'
import { Icon } from 'elements/Icon/Icon'
import { ReactNode, useEffect, useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'

type Props = { itemHeading: string; children: ReactNode; isOpen: boolean }

export const FilterAccordionItem = ({ itemHeading, children, isOpen }: Props) => {
  const AnimatedPanel = animated(AccordionPanel)
  const AnimatedItem = animated(AccordionItem)
  const [buttonRef, { height: buttonHeight }] = useElementSize()
  const [panelRef, { height: panelHeight }] = useElementSize()
  const accItem = useRef<HTMLDivElement>()

  const expand = useSpring({
    config: { friction: 20 },
    height: isOpen
      ? `${Math.floor(panelHeight) + Math.floor(buttonHeight) + 32}px`
      : `${Math.floor(buttonHeight) + 32}px`,
  })

  const fade = useSpring({
    config: { duration: 300 },
    opacity: isOpen ? `1` : `0`,
  })

  const rotate = useSpring({
    config: { friction: 15 },
    scaleY: isOpen ? -1 : 1,
  })

  return (
    <AnimatedItem className="overflow-hidden" style={expand} ref={accItem}>
      <AccordionButton ref={buttonRef}>
        <h3 className="mb-0 flex flex-row items-center text-base font-bold">
          <animated.div className="mr-3 ml-1" style={rotate}>
            <Icon name="chevron-down" size={12} />
          </animated.div>
          {itemHeading}
        </h3>
      </AccordionButton>
      <div ref={panelRef}>
        <AnimatedPanel style={fade}>{children}</AnimatedPanel>
      </div>
    </AnimatedItem>
  )
}
