import clsx from 'clsx'
import { Icon } from 'elements/Icon/Icon'
import { generateFilterString } from '../lib/generateFilterString'
import { useRouter } from 'next/router'
import invariant from 'tiny-invariant'

type Props = {
  // AIRCAST®
  label: string
  // brand-da12805b-c608-48cd-a94e-b0e9231a1b4e
  value: string
  // brand
  attributeCode: string
}

export const Checkbox = ({ value, label, attributeCode }: Props) => {
  const router = useRouter()

  const handleChange = async () => {
    try {
      // get filter param from url
      const filter = router.query.filter || ''
      invariant(typeof filter === 'string')

      router.query.filter = generateFilterString({ filterString: filter, value, attributeCode })
      // set new url
      await router.push(router, null, { shallow: true })
    } catch (e) {
      console.error(e.message)
    }
  }
  const checked = (router.query.filter as string)?.includes(value)

  return (
    <div className="cursor-pointer">
      <label
        htmlFor={`${attributeCode}_${value}`}
        className={clsx('cursor-pointer flex items-center', checked && 'font-bold text-red')}
      >
        <div
          className={clsx(
            'mr-2 h-5 w-5 border-2',
            checked ? 'border-gray-dark bg-gray-dark' : 'border-gray-medium',
          )}
        >
          {checked && <Icon name="checkmark" className="text-white" />}
        </div>
        <input
          type="checkbox"
          className="sr-only mr-2"
          name={`${attributeCode}_${value}`}
          id={`${attributeCode}_${value}`}
          onChange={handleChange}
          checked={checked}
        />
        {label}
      </label>
    </div>
  )
}
