import { FILTER_SEPARATOR, KEY_SEPARATOR, VALUE_SEPARATOR } from '~/lib/constants'

export type FilterObject = { [key: string]: string[] } | {}

/**
 * Turns a string like 'brand:brand-1,brand-2|type:type-1,type-2'
 * into an object like
 * {
 *   brand: ['brand-1', 'brand-2'],
 *   type: ['type-1', 'type-2']
 * }
 * first we split along FILTER_SEPARATOR, then select the key via KEY_SEPARATOR
 * and then the values via VALUE_SEPARATOR
 * @param input string
 * @returns FilterObject
 */
export const parseFilterString = (input?: string): FilterObject => {
  const filters: FilterObject = {}
  if (!input?.length) {
    return filters
  }
  input.split(FILTER_SEPARATOR).forEach((key) => {
    /**
     * key looks like brand:val1,val2
     * split looks like ['brand', 'val1,val2']
     */
    const split = key.split(KEY_SEPARATOR).filter((entries) => entries.length > 0)
    if (split.length >= 2) {
      filters[split[0]] = split[1].split(VALUE_SEPARATOR)
    }
  })

  return filters
}
